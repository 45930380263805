body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: white;
}
html,body,#root{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 14px;
    color: #333333;
}
*{ box-Sizing:border-box;}

.react-reveal{
    align-self: stretch;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.grecaptcha-badge {
    visibility: hidden;
}
